<template>
  <div class="d-inline-block">
    <t-button class="d-inline-block p-0"
              v-p-tooltip.top="'Click for field activity'"
              @click.prevent="$refs.fieldActivityModal.show()"
              variant="link">
      <fluency-icon type="history" class="display-on-hover cursor-pointer"/>
    </t-button>

    <t-modal :id="`field-activity-${field}`"
             ref="fieldActivityModal"
             @show="fetchEvents()"
             size="md"
             title="Recent Activity"
             ok-only>
      <fluency-loader v-if="loadingEvents" dots/>
      <template v-else>
        <span v-if="reversedEvents.length === 0">We did not find any recent activty for this field</span>
        <div class="w-100 h-100 overflow-scroll-y px-3 py-4" v-if="reversedEvents.length > 0">
          <template v-for="a in reversedEvents.reverse()" :key="a.eventId">
            <div :class="[
                      `list-${density}`,
                      `d-flex`,
                      `px-2`,
                      `border-bottom`,
                      {
                        'new': a.hasOwnProperty('new') && a.new,
                        'py-2': density === 'cozy'
                      }
                     ]">
              <div class="flex-grow-1 mt-2 d-flex justify-content-between">
                <div>
                  <div :class="{'d-inline-block': density === 'compact' }">
                    <span class="d-inline-block font-weight-bold">{{a.message}}</span>
                  </div>
                  <br/>
                  <div :class="[{
                            'd-inline-block': density === 'compact',
                            'd-none': density === 'compact',
                            'ml-4 mt-1': density === 'cozy'
                         }]">
                    <span class="d-inline-block font-weight-light">{{prettyDate(a.dateTime)}}</span>
                    <span
                      class="d-inline-block font-italic font-weight-light">by {{a.user === 'Fluency Platform' ? 'System' : a.user}}</span>
                  </div>
                </div>
                <span v-if="advancedMode">({{a.eventId}})</span>
              </div>
            </div>
          </template>
        </div>
      </template>
    </t-modal>
  </div>
</template>

<script>
import FluencyLoader from 'core-ui/components/common/fluencyLoader'

export default {
  name: 'fieldActivity',
  components: { FluencyLoader },
  props: {
    field: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      density: 'compact',
      events: [],
      loadingEvents: true,
      showModal: false
    }
  },
  computed: {
    accountPlanId () {
      return this.$store.getters.activeItem.accountPlanId
    },
    reversedEvents () {
      return this.events?.slice().reverse() ?? []
    },
    advancedMode () {
      return this.$store.getters.userMode === 'advanced'
    }
  },
  methods: {
    prettyDate: function (date) {
      return this.$moment(date).format('DD/MMM/YYYY HH:mm')
    },
    async fetchEvents () {
      if (this.events?.length === 0 || this.events?.some(evt => evt.accountPlanId !== this.accountPlanId)) {
        const response = await this.$res.fetch.accountEvents({
          accountPlanId: this.accountPlanId,
          field: this.field,
          limit: this.advancedMode ? 15 : 5
        })
        this.$setCompat(this, 'events', response || [])
      }
      this.loadingEvents = false
    }
  }
}
</script>

<style scoped>
  .overflow-scroll-y {
    overflow-y: scroll;
  }
</style>
